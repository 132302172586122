import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import * as AppPropTypes from '../../lib/PropTypes';

import BasicButton from '../buttons/BasicButton';

import styles from '../../styles/menus/links-menu.module.css';

const propTypes = {
    items: PropTypes.arrayOf(AppPropTypes.menuItem),
    itemsClassName: PropTypes.string,
    itemClassName: PropTypes.string,
    linkClassName: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    items: [],
    itemsClassName: null,
    itemClassName: null,
    linkClassName: null,
    className: null,
};

function LinksMenu({ items, itemsClassName, itemClassName, linkClassName, className }) {
    return (
        <nav className={classNames([styles.container, className])}>
            <ul className={classNames([styles.items, itemsClassName])}>
                {(items || []).map(
                    ({
                        id = null,
                        href,
                        label = null,
                        external = false,
                        className: customClassName = null,
                    }) => (
                        <li
                            key={id}
                            className={classNames([styles.item, itemClassName, customClassName])}
                        >
                            <BasicButton
                                href={href}
                                external={external}
                                className={classNames([styles.link, linkClassName])}
                            >
                                {label}
                            </BasicButton>
                        </li>
                    ),
                )}
            </ul>
        </nav>
    );
}

LinksMenu.propTypes = propTypes;
LinksMenu.defaultProps = defaultProps;

export default LinksMenu;
